.tree-select-component {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: relative;
  color: #546e7a;
  cursor: pointer;

  .dropdown-trigger {
    outline: 0;
  }

  .tag-list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    .tag {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 1px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 8px;
      z-index: 10;
      background-color: #fff;
      width: 100%;

      .tag-remove {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0;
        background-color: transparent;
        width: 40px;
      }
    }
  }

  .search {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    background-color: transparent;
    padding: 12px 8px;
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }

  .node {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 0 20px 20px 0;

    &.checked {
      label {
        font-weight: bolder;
      }
    }

    &:hover {
      background-color: #cfcfcf;
    }

    label {
      display: flex;
      flex: auto;
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.04px;
      font-weight: 400;
      cursor: pointer;

      .node-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &.directory-selection {
    .node {
      label {
        &:before {
          color: inherit;
          font: normal normal normal 19px 'Material Icons';
          content: '\e2c7';
          margin-right: 4px;
        }
      }

      i + label {
        padding-left: 0;
      }
    }

    .tag-list {
      .tag {
        &:before {
          color: inherit;
          font: normal normal normal 19px 'Material Icons';
          content: '\e2c7';
          margin-right: 4px;
        }
      }
    }
  }

  .radio-item {
    display: none;
    visibility: hidden;
  }

  .dropdown-trigger > span:after {
    font-size: 12px;
    color: inherit;
  }

  .toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 30px;
    font: normal normal normal 19px 'Material Icons';
    line-height: 19px;
    color: #555;
    outline: 0 none;

    &.collapsed::after {
      cursor: pointer;
      content: '\E5CC';
      vertical-align: middle;
    }

    &.expanded::after {
      cursor: pointer;
      content: '\E5CF';
      vertical-align: middle;
    }
  }

  .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 2px solid #aaa;
      transition: all 0.3s ease-in-out;
    }

    &:checked:before {
      height: 50%;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-top-style: none;
      border-right-style: none;
      border-color: #2196f3;
    }
  }
}
